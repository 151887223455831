.btn-orange{
    border: 2px solid #f47824;
    background-color: #f47824;
    color: #FFF;
    padding: 5px;
}

.btn-orange:hover, .btn-blue:hover {
    border: 2px solid #302f2f;
    background-color: #302f2f;
}

.btn-blue{
    border: 2px solid cornflowerblue;
    background-color:cornflowerblue;
    color: #FFF;
    padding: 5px;
    margin:5px;
}

.btn-blue-disabled{
    border: 2px solid #ffffff;
    background-color:#ffffff;
    color: #FFF;
    padding: 5px;
    margin:5px;
}
.MuiTreeItem-iconContainer svg.MuiSvgIcon-root{
    padding:0px;
}
