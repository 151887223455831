body, input, button, select, optgroup, textarea{

    font-family: 'Campton' !important;
}

.container{
    margin: 5px;
    border: 1px solid #ccc;
    background-color: #fff;
}

.header-top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    overflow: hidden;
    padding: 15px 0px;
/*    padding: 10px 0;
*/}
a.navbar-brand {
    color: #fff;
    font-family: 'Exotc350 Bd BT';
    font-size: 32.5px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 26px;
    text-transform: uppercase;
}
.colored {
    color: #F47824 !important;
}
.form-control{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    height:40px;
    background-color:#ffffff;
    color: #272729;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
}

.react-datepicker__input-container
{
    border-bottom-right-radius: 0px;
    border-top-right-radius: 0px;
    height:40px;
    background-color:#ffffff;
    color: #272729;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px; 
}

.form-control:focus{
   outline: none;
   color: #495057;
   background-color: #fff;
   border-color: #E0DDDD;
   outline: 0;
   box-shadow: 0 0;
}
.input-group-text{
    border-bottom-right-radius: 8px;
    border-top-right-radius: 8px;
    background-color:#ffffff;
    border-left:0px;
    height:40px;
}
.bi.bi-search {
    fill: #f47824;
}
.event-bg-btn {
    border-radius: 8px;
    background-color: #F47824;
    padding: 9px 15px;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}

.btn-orange{
    border: 2px solid #f47824;
    background-color: #f47824;
    color: #FFF;
    padding: 5px;
}

.btn-orange:hover, .btn-blue:hover {
    border: 2px solid #302f2f;
    background-color: #302f2f;
}



.event-bg-btn:hover{
    border:1px solid #f47824; 
    color: #F47824;
    background-color: #fff;
}
.event-outline-btn:hover{
    border:1px solid #f47824; 
    color: #fff;
    background-color: #F47824;
}
.event-outline-btn {
    border-radius: 8px;
    background-color: #fff;
    padding: 9px 25px;
    color: #F47824;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    border:1px solid #f47824;
}
.menu {
    position: relative;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
    color: #ffffff;
    display:inline-block;
    margin-top:23px;

}
.navbar-toggler:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
.navbar-toggler-icon{
    width: 0px;
    height: 0px;
}
.navbar-toggler-icon:after {
    width: 10px;
    border: 3px solid #fff;
    content: "";
    display: block;
    background-color: #fff;
    border-radius: 3px;
    position: absolute;
    top: 15px;
    left: 10px;
}
.navbar-toggler-icon:nth-child(2):after{
   top:25px;
   width:18px;
   left: 0;
}
.navbar-toggler-icon:nth-child(3):after{
    top:35px;
    left: 0;
 }
 /* Footer */
 .bg-dark-gray{
     background-color: #404146;
 }
 .items-list li a {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.5;
}
.items-list li {
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;
    opacity: 0.5;
}
.items-list li {
    list-style-type: none;
    
}
.items-list {
    padding-left: 0;
}
.list-item-title{
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-align: left;
    color:#ffffff;
}
.social-links  li {
    list-style-type: none; 
    float:left; 
    margin-left: 37px;
/*    margin-left: 45px;
*/}
.social-links {
    padding-left: 0;
}
.social-links li a {
    color: #FFFFFF;
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 22px;
}
.copy-right p {
    color: #FFFFFF;
    font-family: 'CircularStd-Book';
    font-weight: 300;
    letter-spacing: 0;
    line-height: 18px;
}
/* Banner */
.banner-content {
    display: flex;
    flex-direction: column;
}
.banner-bg{
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    padding:150px 0 100px;
    height: 612px;
}
.banner-title {
    color: #FFFFFF;
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 46px;
    text-align: center;
    margin:0 auto;
    padding-bottom:22px;
  }
  .banner-text {
    opacity: 0.8;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin:0 auto;
    padding-bottom:36px;
  }
  .event-btn {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 12px 40px 12px 20px;
    color: #2D272C;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 40px;
}
.event-btn span i {
    font-size: 28px;
    color: #f47824;
}
.icon-width{
    Width: 32px;    
    Height:29px;
    
}
.bg-dark-yellow{
    background-color: #F47824;
    background-size: cover;
    background-repeat: no-repeat;
    
    padding:100px 0;
}
.title{
    color: #FFFFFF;
    font-size: 28px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 36px;
    position: relative;
    text-transform: capitalize;
}
.p-text{
    opacity: 1;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
}
.video-section {
    padding: 10px;
    border: 4px solid #fff;
    border-radius: 16px;
    position: relative;
}
.border-radius{
    border-radius: 16px;
    
}
.icon-play {
    position: absolute;
    top: 46%;
    left: 46%;
    background-color: #fff;
    padding: 17px 22px;
    text-align: center;
    border-radius: 50%;
    width: 56px;
    height: 56px;
    color: #f47824;
    vertical-align: middle;
    box-shadow: 0px 0px 3px 11px #ffffff9e;
}
.icon-play span i{
    font-size:22px;
}
.space{
    padding:64px 0;
}
.small-heading{
    font-size: 24px;
    font-weight: 300;
    letter-spacing: 0;
        font-family: 'Campton';

    line-height: 29px;
    font-weight: 500!important;
    color: #272729;
}
.item-content {
    border-radius: 8px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 16px 0 rgba(0,0,0,0.12);
    padding: 32px 25px 38px 25px;
    margin: 3px;
    display: inline-block;
    width:100%;
    height: 370px;
  }
  
.circle{    
    margin-bottom: 24px;
    display: block;
    width: 96px;
    height: 96px;
    background-color: #D8D8D8;
    border-radius: 50%;
    
}
.owl-dots {
    padding-left: 10px;
}
.item-content h2{
    color: #272729;
    font-size: 22px;
/*    font-size: 24px;
*/    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    margin-bottom: 16px;
}

.owl-item {
    display: inline-block;    
    vertical-align: middle;

}
.owl-nav {
    text-align: center;
    margin-right: 0;
    margin-top: 30px;
}
.owl-prev, .owl-next {    
    border-radius: 4px;
    background-color: #fde4d3;   
    width: 48px;
    height: 48px;
    border: 0px;
    margin-right: 10px;
    
}
.owl-prev span, .owl-next span{
    font-size: 60px;
 color: #2d272cbf;

/*    color: #2D272C;
*/    line-height: 0.5;
    font-weight: 100!important;
}
button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}
/*.nonloop{
    overflow: hidden;
}
*/

.joining-us-bg{
    position: relative;
    background-color: #f4f4f4;
/*    background-image: url('../images/banner_3.png');    
*/ 

   background-position: center;
    background-repeat: no-repeat;
    background-size:cover;

}



.icon-box{
    position: relative;
}
.icon{
    height: 96px;
    width: 96px;
    opacity: 0.9;
    background-color: #FFFFFF;
    border-radius:50%;
    display: block;
    margin: 0 auto;
}
.sub-title{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    padding-top: 14px;
}
.text{
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
}
.img-width {
    width: 210px;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 9;
    top: 0;
    right: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 60px;
  }
  
  .sidenav a.nav-link {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
    color: #582949;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 56px;
    
  }
  .sidenav a.nav-link.active{
      font-weight: 500;
  }
  
  .sidenav a.nav-link:hover {
    background-color: #fde4d3;
  }
  
  .sidenav .closebtn {
    position: absolute;
    top: 0;
    left: 32px;
    font-size: 36px;
    color: #000;
    text-decoration: none;
    font-weight: 600;
  }
  .sign-in-up-btn{
    padding: 8px 8px 8px 32px;
  }
  .author-img {
    width: 70px;
    height: 70px;
    display: inline-block;
    border-radius: 50%;
}
.author-img img{
    height: 70px;
    object-fit: cover;
    border-radius: 50%;
}
.author-info p{
    display:inline-block;
    margin-left:40px;
}
.author-info .author-title{
    display:block;
}
.author-info {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.review{

    color: #272729!important;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
}
.author-title{
    color: #272729;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 25px;
    display:block;
    margin-top:15px;
}
.author-type{
    color: #272729;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 19px;
}
.left-section{
    width:100%;
    display:block;
}
.right-section{
    display:none;
}
.owl-dot {
    width: 12px;
    height: 12px;
    position: relative;
    background-color: #D8D8D8 !important;
    margin-left: 10px;
    border-radius:2px;
}
.owl-dot.active {
    background-color: #F47824 !important;
}
.sub-heading {
    font-size: 32px;
    font-weight: 600;
    color: #272729;
    line-height: 60px;
}
.circle-box{
    height: 271px;
    width: 271px;
    border: 1px solid #979797;
    background-color: #D8D8D8;
    border-radius:50%;

}
.btn-warning{
    background-color: #f47824 !important;
    color: #fff;
    border-radius: 8px;
    padding: 12px 0;
    font-weight: 500;
    border:1px solid #f47824 !important;
}

.btn-info{
    background-color: #39779E !important;
    color: #fff;
    border-radius: 5px;
    padding: 8px 8px;
	text-align:left;
	font-weight:300;
	font-size:0.9rem;
    border:1px solid #39779E !important;
}
.payblock{padding:20px; width:100%;}
.speight{padding: 9px; margin:10px; font-size:0.9rem; font-weight:300; color:#282828;}
.greight{font-size:0.7rem; font-weight:300; color:#f47824;}

.text-warning{
    color:#f47824 !important;
}
.modal-header .close {
    margin: -1.5rem -2rem -1rem auto;
    background-color: #fff;
    padding: 0.5rem;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    line-height: 1px;
    opacity: 1;
    box-shadow: 1px 0px 7px 0px #9c9595;
}
.modal-header .close:hover{
    opacity: 1 !important;
}
.font-size-large{
    font-size:3rem;
}
.modal-content{
    border-radius:8px;
}
.page-title{
    color: #272729;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 28px;
    margin-left:10px;
}
.filter-button {
    border-radius: 6px;
    background-color: #d0d0d0;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 18px;
    text-align: center;
    display: inline-block;
    margin-bottom: 24px;
}
.btn:focus{
    box-shadow: 0;
    outline: 0;
}
.filter-button:focus{
    outline: none;
    box-shadow: 0px;
}
.filter-button.active{
    background-color: #8489B2;
}
.filter-button:hover{
    background-color: #8489B2;
    color: #FFFFFF;
}
.event-title{
    color: #2D272C;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-decoration: none;
}
.event-link:hover{
    text-decoration: none;
}
.event-link img{
    border-radius: 8px;
    width:100%;
}
.label--hide label{ 
    display:none;
}
.label--hide label {
    color: #929292;
    font-weight: 500;
    font-size: 14px;
}
.label--hide label::placeholder {
    color: #929292;
    font-weight: 500;
    font-size: 14px;
}
.font-small{
    font-size:14px;
}
.wishlist{
    top:0;
    right:0;
}
.bg-light-blue{background-color: #8489B2;}
.slider-for .slick-slide{
    display: block;
}
.slider-nav .slick-slide{
    display: inline-block;
}
.thumbs-items img{
    border-radius:8px;
}
.nav-tabs{
    border-radius: 8px;
    background-color: #FFEED6;
}
.nav-tabs li{
    padding:8px 22px;
}
.nav-tabs li a{
    opacity: 0.5;
    color: #2C2627;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
    text-decoration: none;
}
.nav-tabs li a.active{
    opacity: 1;
    color: #2C2627;
    position:relative;
    padding: 0px 0 14px 0;
}
.nav-tabs li a.active:after{
    content: "";
    height: 2px;
    width: 32px;
    border-radius: 1px;
    background-color: #F47824;
    position: absolute;
    bottom:0;
    left:0;

}
.heading{
    color: #2C2627;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.border-outer{
    border: 1px solid #cccccc;
    background-color: #FFFFFF;
}
.inner-left ul li {
    position: relative;
    padding-right: 14px;
    display: inline-block;
    opacity: 0.7;
    color: #2D272C;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
  
}
.inner-left ul li:before {
    content: "";
    display: inline-block;
    border-radius: 50%;
    margin-right: 4px;
    height: 8px;
    width: 8px;
    border: 2px solid #F47824;
    opacity: 0.7;
}
.inner-left ul{
    padding-left:0;
}
.price span{
    color: #8489B2;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
}
.price span a.btn{
    color: #FFFFFF;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
    border-radius: 6px;
    background-color: #8489B2;
    padding:5px 20px;
    text-transform: capitalize;
}
.icon-small {
    width: 40px;
}
.share-details{
    color: #8489B2;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: none;
}
.event-sub-heading {
    color: #F47824;
    font-family: Campton;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 32px;
}
.event-heading{
    color: #2C2627;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 20px;
  }
 span.bg-warning {
    border-radius: 8px;
    padding: 2px 10px;
    background-color: #f47824 !important;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
}
.rating-package.bg-warning {
    border-radius: 4px;
    padding: 5px 7px;
    background-color: #f47824 !important;
    color: #fff;
    font-size: 12px;
    line-height: 20px;
    font-weight: 300;
}
.review-img{
    width:24px;
    height:24px;
    border-radius: 50%;

}
.author-name{
    color: #2C2627;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.post-date{
    opacity: 0.5;
  color: #2C2627;
  font-size: 11px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 16px;
    font-family: 'Campton';

}
.post-description{
       opacity: 0.8;
    color: #2C2627;
    font-size: 13px;
    font-family: 'Campton';
    font-weight: 500;
    letter-spacing: 0;
    line-height: 20px;
}
.small-img{
    display: inline-block;
    width: 48px;
    margin-bottom: 20px;
}

.address {
    opacity: 0.8;
    color: #2C2627;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 0;
    line-height: 24px;
}
.distance {
    font-size: 12px;
    margin-left: 22px;
}
.progress {
    margin: 15px;
    height: 5px;
    border-radius: 0px;
    display: none;

}
.progress-bar { 
    background-color: #f47824;
}
.search-form .form-group {
    float: right !important;
    transition: all 0.35s, border-radius 0s;
    width: 52px;
    height: 52px;
    background-color: #fff;
    border-radius: 25px;
  }
  .search-form .form-group input.form-control {
    padding-right: 40px;
    border: 0 none;
    background: transparent;
    box-shadow: none;
    display:block;
  }
  .search-form .form-group input.form-control::-webkit-input-placeholder {
    display: none;
  }
  .search-form .form-group input.form-control:-moz-placeholder {
    /* Firefox 18- */
    display: none;
  }
  .search-form .form-group input.form-control::-moz-placeholder {
    /* Firefox 19+ */
    display: none;
  }
  .search-form .form-group input.form-control:-ms-input-placeholder {
    display: none;
  }
  .search-form .form-group:hover,
  .search-form .form-group.hover {
    width: 100%;
    border-radius: 4px 25px 25px 4px;
  }
  .search-form .form-group span.form-control-feedback {
    position: absolute;
    bottom: 22px;
    right: 22px;
    font-size: 14px;
    background-color: #f47824;
    padding: 4px;
    border-radius: 50%;
  }
  .rating{
      bottom:10px;
      right:10px;
  }
  .price{
    color: #8489B2;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
  }
  .img-rounded img {
    border-radius: 8px;
    width: 100%;
}
.btn-outline-warning {
    border-radius: 8px;
    border: 1px solid #f47824;
    padding: 8px 25px;
    color: #f47824;
    font-size: 14px;
    line-height: 18px;
}
.btn-outline-warning:hover {
    border: 1px solid #f47824;
    color: #fff;
    background-color:#f47824;
}
.add-on-box{
    padding:12px 16px;
    border-radius:8px;
}
.icon-done {
    top: -12px;
    right: -6px;
}
.add-on p {
    opacity: 0.6;
    color: #2D272C;
    font-size: 12px;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 20px;
}
.edit{
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
}
.box-shadow{
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);
}
.package-details {
    bottom: 0;
    left: 0;
    padding: 16px;
}
.package-details h4{
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 16px;
}
.package-details h2{
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 32px;
}
.add-on-chckout{
    opacity: 0.5;
    color: #2D272C;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
}
.border-primary{
    border-color: #39779E !important;
}
.btn-outline-success{
    border-radius:50px;
    padding:8px 25px;
}
.event-book p{
    opacity: 0.9;
    color: #484848;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
}
.event-book h2{
    font-size: 24px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 28px;
}



@media (min-width: 576px){

    .modal-dialog {
        max-width: 700px;
/*        max-width: 420px;
*/        margin: 1.75rem auto;
    }
    .banner-title {
        font-size: 32px;
        line-height: 46px;
    }
    .banner-bg {
        padding: 200px 0 100px;
    }
    .title {
        font-size: 32px;
        line-height: 46px;
    }
    .p-text {
        opacity: 0.8;
        color: #FFFFFF;
        font-size: 20px;
        line-height: 24px;
    }    
    .owl-nav {
        text-align: right;
        margin-right: 80px;
        margin-top: 30px;
    }
    .left-section{
        width:100%;
        display:inline-block;
    }
    .right-section{
        display:none;
    }
    .menu {
        margin-top:0px;
    }
}
@media only screen and (min-width:768px){
    .banner-title {
        font-size: 56px;
        line-height: 64px;
    }
    .banner-bg {
        padding: 200px 0 10%;
    }
    .title {
/*        font-size: 46px;
*/        line-height: 60px;
font-size: 43px;
    }
    .p-text {
        opacity: 0.8;
        color: #FFFFFF;
        font-size: 20px;
        line-height: 24px;
    }    
    .owl-nav {
        text-align: right;
/*        margin-right: 80px;
*/        margin-top: 30px;
    }
    .left-section{
        width:100%;
        display:inline-block;
    }
    .right-section{
        display: none;
    }
}
@media only screen and (min-width:992px){
    .left-section{
        width:49%;
        display:inline-block;
    }
    .right-section{
        width:49%;
        display:inline-block;
    }
    .wrap {
        white-space: nowrap;
    }
}





  
  /*application_sec*/


  
.application_sec{
    padding: 20px 0px;
}
  

  .application_card{
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .12);
  }

.form_card{
    padding: 30px 20px;
    border-radius: 10px;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .12);
  }
  
form.user_form .form-control{
	border-bottom-right-radius:8px;
	border-top-right-radius:8px;
}
 
.form_blk{padding : 50px }
.form_mn{padding : 50px 0px; }
  .application_row{
    width: 90%;
    margin: 40px auto;
  }

.application_card h3{
    font-weight: bold;
    font-size: 30px;
}

.application_card p {
    color: #5a5a5c;
    margin-top: 20px;
    font-size: 15px;
    font-weight: lighter;
        font-family: 'Campton';

}

.form_card h3{
    font-weight: bold;
    font-size: 30px;
}

.form_card p {
    color: #5a5a5c;
    margin-top: 20px;
    font-size: 15px;
    font-weight: lighter;
        font-family: 'Campton';

}

  /*application_sec*/



.item-content p{
    font-weight: 300;
    color: #5a5a5c;
    font-size: 15px;
}





@media(max-width: 767px){
    .menu{
        line-height: 0px!important;
    }
    .title{
        margin-bottom: 40px;
        font-size: 27px;
    }

    .icon-box{
        margin-bottom: 40px;
    }
    .Benifits{
        padding-bottom: 0px!important;
    }
    .Professional-sec h2{
font-size: 23px!important;
margin-bottom: 0px!important;
    }
    .author-title{
        font-size: 17px;
    }
    .review{
        font-size: 16px;
        line-height: 25px;
    }
    .small-heading{
        font-size: 18px;
    }
    .copy-right p{
        line-height: 26px;
    }
}









@media (min-width: 768px) and (max-width: 1030px){
    .banner-title{
        font-size: 43px;
    }
    .title{
        font-size: 28px;
        line-height: 40px;
    }

    .DOWNLOAD img{
width: 150px;
    }
    .small-heading{
        padding-bottom: 0px!important;
    }
 }



/*eventstan_sec*/

.eventstan_sec{
    padding: 100px 0px;
    background-color: #fef3e6;
}

.eventstan_sec h2{
 /*    height: 180px;
  width: 1084px;*/
  color: #2D2D2D;
  font-family: Campton;
  font-size: 46px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
  text-align: center;
}



.Joining_sec{
    text-align: center;
    background-color: transparent!important;
    width: 50%;
    margin:0pc auto;
}
.Joining_sec h4{
    color: #F47824;
    font-weight: bold;
}

.application_sec h4{
    color: #F47824;
    font-weight: normal;
	font-size:0.8rem;
	text-transform:uppercase;
}

.application_sec .center{
    text-align:center;
}

.Joining_sec p{
     color: #2D2D2D;
  font-family: Campton;
  font-size: 28px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 40px;
  font-weight: 400;
  text-align: center;
}


.pdl{
    padding-left: 0px;
}

.pdr
{
    padding-right: 0px;
}

.Better_sec {
    padding: 0px 100px;
}
.Better_sec p{
    color: #000!important;
  font-family: Campton;
  font-size: 28px;
opacity: 2;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 40px;


}



.how-it-work{
    margin-bottom: 50px;
}

.Contact_sec{
    background-color: #fef3e6;
    margin-top: 100px;
    padding: 50px 0px;

}

.Contact_sec h3{
color: #2D2D2D;
  font-family: Campton;
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
    text-transform: capitalize;
}


.custom_tabs ul{
background-color: transparent!important;
border-radius: 0px!important;
border:none!important;
}


.custom_tabs, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
/*   padding: 13px 65px;
*/    color: #F47824!important;
}

.custom_tabs ul li a{
    font-size: 24px!important;
}


.custom_tabs li {
    border: 2px solid #F47824!important;
}


.custom_tabs, .nav-tabs .nav-link{
    border:3px solid transparent;
    border-radius: 8px!important;
  }



.custom_tabs, .nav-tabs li a.active:after{
background: transparent!important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus{
    border-color: transparent!important;
}

.custom_tabs ul{
margin-top: 30px;
}

/*.custom_tabs, .nav-tabs li
{
    padding:0px!important;
}

/**/

/*.custom_tabs, .nav-tabs li a.active:hover{
    border-color: transparent!important;
}*/

.custom_tabs, .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
    border-color: transparent!important;
    background-color: transparent;
}
.custom_tabs, .nav-tabs li a.active
{
    padding: auto!important;
}

.Contact_sec form{
        font-family: 'Campton Book';

}



.Contact_sec form label{
    color: #000!important;
    opacity: 0.7;
  color: #2D272C;
  font-family: Campton;
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 10px;
}

.Contact_sec form button, .application_sec form button{

  border: 1px solid #DCDCDC;
  width: 100%;
  font-size: 20px;
    font-family: Campton;

  padding: 15px 0px;
  border-radius: 8px;

  margin-bottom: 40px!important;
  display: inherit;
  background-color: #F47824;
}

.Contact_sec form button:hover{
    border-color: transparent;
}


.Contact_sec form input{
    border:1px solid #ced4da!important;
/*    height:72px;
*/    padding: 20px 30px;
    border-radius: 8px;
    letter-spacing: 1px;
font-size: 16px;
        font-family: 'Campton Book'!important;


    height: 65px;
}
.Contact_sec form select{
    border:1px solid #ced4da!important;
    height:72px;
    border-radius: 8px;
    font-size: 16px;

              font-family: 'Campton Book'!important;

    color: #ced4da;

}
.Contact_sec form textarea{
    padding: 20px;
              font-family: 'Campton Book'!important;


}

.Contact_sec form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ced4da;
          font-family: 'Campton Book'!important;

  opacity: 1; /* Firefox */
}

.Contact_sec form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ced4da;
          font-family: 'Campton Book'!important;

}

.Contact_sec form input::-ms-input-placeholder { /* Microsoft Edge */
  color: #ced4da;
}




.Contact_sec form textarea::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #ced4da;
  opacity: 1; /* Firefox */
}

.Contact_sec form textarea:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #ced4da;
}

.Contact_sec form textarea::-ms-input-placeholder { /* Microsoft Edge */
  color: #ced4da;
}






.Contact_section{
    width: 70%;
    margin: 0px auto;
    display: inherit;
}

.Contact_sec
{
    padding: 0px;
}

/*.custom_tabs, .nav-tabs > li, .nav-pills > li {
    float:none;
    display:inline-block;
    *display:inline;
     zoom:1;
}

.custom_tabs, .nav-tabs, .nav-pills {
    text-align:center;
}*/

.About_header{
    background-color: #404146e6!important;
    padding:20px 0px;
}

.about_sec{
    padding: 100px 0px;
    margin-top: 100px;
}

.about_sec h2{
    color: #2D272C;
  font-family: Campton;
  font-size: 96px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 60px;
}

.about_sec h3{
color: #2D272C;
  font-family: Campton;
  font-size: 56px;
  font-weight: 300;
  letter-spacing: 0;
  line-height: 60px;
}


.line_img{

        top: 30%;
    position: absolute;
    left: 1%;
    z-index: -1;

}


.about_content p{
    font-weight: 300!important;
    line-height: 28px;
    color: #818181!important;
font-size: 24px!important;
}


.about_content h2{
    padding: 0px;
    margin: 0px;
    line-height: 45px;
}

.about_content h3{
font-size: 24px!important;
margin-top: 5px;

}



.second_part{
    padding: 50px 0px 80px 0px;
}


/*about page resposnive css */

@media (max-width: 767px){
    .about_sec{
        padding-bottom: 50px;
    }
    .about_sec h2
    {
        font-size: 60px;
    }
    .about_sec h3{
        line-height: 40px;
        font-size: 25px;
    }
    body{
margin-right: 0px!important;
    }
    .Better_sec{
        padding: 0px 20px;
    }

    .about_content p{
        font-size: 20px!important;
    }
    .pdr{
        padding-right: 0px;
    }
    .pdl{
        padding-left: 0px;
    }

    .founder{
        padding: 0px 30px!important;
    }


}




.custom_tabs ul li{
    /*
        width: 200px;
    float: none;
    margin: 0px auto;
    display: inline-block;*/
}

.custom_tabs .nav-tabs li a.active
{
    padding-bottom: 0px!important;
}

/*.custom_tabs .nav-tabs li:first-child {
    border-right: none!important;
}
*/
.custom_tabs li {/*
   border: 2px solid #F47824!important;
    border-radius: 8px;
    margin-right: 30px; 
    background-color: #fff!important;*/

    border: 2px solid #F47824!important;
    border-radius: 8px;
    margin-right: 30px;
/*    width: 100px;
*/    text-align: center;
    width: 26%;
/*    background-color: #fff!important;
*/}


/*
.custom_tabs li .active{
    border-right: none!important;
}
*/


/*about page resposnive css */



/*profestional page responsive css*/
@media (max-width: 767px){
    .Joining_sec{
        width: 100%!important;
    }
    .eventstan_sec h2{
            color: #2D2D2D;
    font-family: Campton;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 40px;
    text-align: center;
    }

.sub-heading{
    font-size: 20px!important;
}

.Better_sec p{
    font-size: 20px;
    line-height: 33px;

}
.Better_sec_right{
    margin-top: 30px!important;
    margin-left: 15px!important;
}
.Joining_sec p{
    font-size: 20px!important;
    line-height: 32px!important;
}

.Contact_section{
    width: 100%!important;
}
.Contact_sec h3{
    font-size: 23px!important;
    line-height: 30px;
}
.custom_tabs li{
    width: 80%!important;
    padding: 0px!important;
    margin-bottom: 30px!important;
}

.Contact_section ul li a{
    font-size: 20px!important;
}
.Contact_sec form label{
    font-size: 15px!important;
}

.Contact_sec form button{
    font-size: 17px;
}

.menu{
    margin-top: 0px!important;
}
}

.Contact_sec form label{
        font-family: 'Campton Book';

}
.tab-content > .tab-pane{
    padding-left: 0px;
}


.about_sec h2{
    margin-top: 20px;
}

/*profestional page responsive css*/


.space h2{
    color: #000!important;
}



.icon-box img{
    width: 100px!important;
    margin: 0px auto;
}

.icon-box h3{
    color: #000!important;
    font-size: 20px;
    font-weight: 600;
        font-family: 'Campton';

}

.icon-box p{
    color: #000!important;
      font-family: Campton;
font-weight: 300!important;
font-size: 14px!important;
}


.application_card{
    height: 310px;
}


.Joining_section{
    padding: 0px 0px 70px 0px;
    text-align: center;
}


.Joining_section h2{
            font-family: 'Campton';

    font-size: 48px;
    font-weight: 600;
    line-height: 60px;
}


.Joining_section p {
    font-size: 24px;
            font-family: 'Campton';
font-weight: 500;
    color: #8d8d8e;

}


.work_part{
    width: 60%;
    margin: 0px auto;
}


.mr-0{
    margin-top: 0px;
}


.height_manage{
    height: 400px!important;
}





/*////// 26nov changes ///*/

/*
button.btn.event-bg-btn.mr-lg-0.mr-xl-2{
    display: none;
}*/

.navbar-toggler-icon{
    display: none;
}
.menu{
    display: none;
}
.title{
    text-transform: initial!important;
}
.Contact_sec h3{
        text-transform: initial!important;
}

.event-title{

        text-transform: initial!important;

}



@media (max-width:767px){
   .application_card{
       margin-bottom:50px;
   } 
   .height_manage{
       height:430px!important;
   }
   .application_card h3 {
       font-size:23px;
   }
   .navbar-brand{
    padding-bottom: 20px!important;
   }

    .right_s{
        width: 40%;
        float: left;
      }
      .left_s{
        width: 60%;
        float: left;
      }
      a.navbar-brand{
        font-size: 22px;
      }
      .Professional_button{
        font-size: 11px;
        padding: 5px 15px;
        margin-top: -15px;
        float: right;
      }
 
}


@media(min-width: 575px) and (max-width: 1030px){
    .Professional_button{
        width: 230px!important;
    }
}



/*////// 26nov changes ///*/




/*event page css */



.back-btn a{
text-transform: uppercase;
font-size: 12px;
font-weight:600;

  color: #2D272C!important;
  right: 2px;

      /*  font-family: 'Campton';
*/
text-decoration: none;
}



.fa-angle-left{
      font-weight: bold;
    font-size: 19px!important;
    position: relative;
    top: 2px;
}

.filter-button{
    font-size: 14px!important;
    padding: 10px 20px!important;
    margin-right: 10px!important;
        font-family: 'Campton Book'!important;
    color:#7A7A7A!important;
}

/*.active{
    background-color:#8489B2!important;
    color: #fff!important;
}*/


.search{
    font-weight: 500;
            font-family: 'Campton Book'!important;

}
.event-title{
    font-weight: bold;
font-family: 'Campton Book'!important;


}
.filter-button:hover{
    color: #fff!important;
}
.filter-button:focus{
    box-shadow: none;
}

/*.custom_modal{
    width: 1000px!important;
}*/
/*event page css */

/*modal css*/
.cross{
position: absolute;
right: 22px;
font-size: 20px;
height: 30px!important;
width: 30px!important;
padding: 0px 1px 1px 3px!important;
font-weight: 200!important;

background: #838383!important;
    color: #fff;
    border: 2.5px solid #fff!important;
    font-weight: normal;

}

.cross:hover{
    color: #fff!important;
}
.custom_modal h4{
      text-align: center;
    display: inline-block;
    font-weight: bold;
    width: 100%; 
}


.custom_modal form input{
  height: 40px;
  border: 1px solid #DCDCDC!important;
  border-radius: 8px!important;
  background-color: #F7F7F7!important;
}

.custom_modal form select{
  height: 40px;
  border: 1px solid #DCDCDC!important;
  border-radius: 8px!important;
  background-color: #F7F7F7!important;
  width: 100%;
    color: #D1D1D1!important;
font-weight: 300!important;
}
.custom_modal form select:focus{
    outline: none!important;
}


.custom_modal form label{
  opacity: 0.7;
  color: #2D272C!important;
      font-family: 'Campton Book';

/*  font-family: Campton;
*/  font-size: 12px;
  font-weight: 600;
  line-height: 24px; 
}

.custom_modal form button{
      background-color:#D1D1D1!important;
      border-radius: 12px!important;
      border-color: #D1D1D1!important;
     height: 48px!important;
  width: 328px!important;
margin: 0px auto;
  color: #9F9F9F;
font-weight: 600;

}
.custom_modal form button:focus{
    box-shadow: none!important;
}

 .custom_modal form button:hover{
    background-color: transparent;
      border-color: #D1D1D1!important;
      color: #000!important;
 }
 .custom_modal form button{
      margin: 30px auto 20px auto;
    display: inherit; 
 }


.custom_modal form input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #D1D1D1!important;
  font-size: 16px;
        font-family: 'Campton';
  font-weight: 300!important;
  opacity: 1; 
  /* Firefox */
}

.custom_modal form input:-ms-input-placeholder { /* Internet Explorer 10-11 */
color: #D1D1D1!important;
  font-size: 16px;
        font-family: 'Campton';
  font-weight: 300!important;
  opacity: 1; }

.custom_modal form input::-ms-input-placeholder { /* Microsoft Edge */
color: #D1D1D1!important;
  font-size: 16px;
        font-family: 'Campton';
  font-weight: 300!important;
  opacity: 1; }

 



/* Style the active class, and buttons on mouse-over */




.private_btn button
{
    background-color:red!important;
}

.event-bg-btn a{
    text-decoration: none;
    color: #fff;
}

.event-bg-btn a:hover{
    color: #F47824;
}

input.w-100{
  color: #D1D1D1;
  font-weight: 300!important;
}



.demo{
    background: #8489B2!important;
        width: 45%;
    float: left;
    padding: 9px;
    border-radius: 8px;
    text-align: center;
    color: #fff;
    margin-right: 5%;
/*    width: 30%!important;
*//*    float: left;
*/    }


.demo:hover{
        text-decoration: none;

    color: #fff;

}

.demo:nth-child(3){
    color: #8489B2;
    border:1px solid #8489B2;
    background-color:transparent!important;
}


.event_sec p{

    color: #404146cf;
    font-size: 16px!important;
    font-weight: 300;
          font-family: 'Campton Book';

}


.add_sec{

padding: 20px;
border: 1px solid #DCDCDC;
border-radius: 8px;
margin-top: 20px;
}
.add_sec h4{
    font-size: 18px;
          font-family: 'Campton Book';
font-weight: bold;
}

.add_sec h4 small{
    font-size: 11px;
    color: #F47824;
    font-weight: bold;
}

.add_sec p{
              font-family: 'Campton';
              font-size: 14px!important;
}
.add_sec p span{
    font-size: 12px;
    color: #8489B2!important;
    font-weight: 500;
    margin-top: 5px;
   /* font-size: 12px;
    color: #8489B2!important;*/
}
.add_sec p{
margin:0px;
}

.add_sec:hover{
    border-color: #F47824 !important;
    box-shadow: 0 2px 24px 0 rgba(0, 0, 0, .12);
}

.button{
    background-color: #D1D1D1!important;
    border-radius: 12px!important;
    border-color: #D1D1D1!important;
    height: 48px!important;
    width: 328px!important;
    margin: 0px auto;
    color: #9F9F9F;
    font-weight: 600;
    margin-top: 10px;
    outline: none;
    border:2px solid #D1D1D1;
}


.Package_sec{
    padding: 50px 0px;
    background-color: #F7F7F7!important;
}

.Package_sec h2{
    font-weight: bold;
font-size: 24px;
margin-bottom: 20px;}

/*.btn a{
    text-decoration: none;
    color: #000!important;
}
*/

/*modal css*/


.custom_modal .btn a{
     text-decoration: none;
    color: #000!important;
}


.party_sec {

    background-size: cover;
    padding: 20px;
    border: 2px solid #f7f7f7;
    border-radius: 8px;
/*    margin: 0px 20px;
*/margin-bottom: 20px;


}

.right_sec .party_sec{
    margin: 0px 20px 15px 20px;
}

.party_sec_2{
        background-size: cover;
}


.party_sec button{
    background-color: #F47824;
    border-radius: 4px;
    border: none;
    outline: none;
    padding: 5px 12px;
 font-family: 'Campton'!important;


/*font-weight: 300;
*/
    color: #fff;
    float: right;
    font-size: 12px;
}


.party_sec p{
margin:35% 0 0 0;
font-weight: 500;
color:#fff;
}

.clearfix{
    clear: both;
}



#basic-text1{
    background-color: #f4782461;
    border-radius: 50%;
    margin-left: 10px;
}



.search_sec{
    border: none;
}
.search_icon{
    border-bottom: 1.5px solid #97979738;
    padding-bottom: 10px;

/*    opacity: 20%;
*/}

.input-group-text{
    border: none;
}

.bottom_space{
    margin-bottom: 0px!important;
}

.book_text{
    margin-bottom: 0px;
        font-family: 'Campton'!important;

}
.book_text {

                      font-family: 'Campton'!important;

font-size: 16px;
margin-top: 10px;
}

.book_text small{
    margin-left:20px;
    color: #8489b2;
}


.book_text button{
    outline: none;
    background: transparent;
    padding: 8px 23px;
    border-radius: 8px;
    font-size: 14px;
    margin-top: -40px;
    color: #F47824;
    float: right;
    border: 1px solid #F47824;
    font-family: 'Campton'!important;
}

.book_text span {
        color: #8489b2;
margin-top:7px;
display: inherit;
}

.right_sec{ 
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.1);

    margin-top: 20px;
border-radius: 8px; }

.right_sec h2{
 font-size: 18px; 
 margin:20px 0 10px 20px;  
/* margin-left: 20px;
*/}


.venu_sec{
    padding: 5px;
    margin: 0px 15px;
}


.Birthday_text{
    margin-left: 0px!important;
        font-family: 'Campton'!important;
font-weight: bold;

}

.right_sec h4{
    font-size: 14px!important;
}

.right_sec p{
    font-size: 12px!important;
}

.adons{
    color: #F47824;
    font-weight: bold;
    text-align: center;

}

.adons strong{
    font-size: 16px!important;
}


.pay_button{
  

      border-radius: 12px;
    background-color: #F47824;
    outline: none;
    color: #fff;
    padding: 13px 25px;
    margin: 0px auto;
    display: inherit;
    margin-top: 40px;
    font-size: 15px!important;
    border: none;
margin-bottom: 25px!important;
    text-align: center;

}

.pay_button span{
    border-right: 1px solid #fff;

}

.right_sec .add_sec{
    padding: 13px!important;
}

.right_sec .party_sec p{
    font-size: 14px!important;
}

.big_img{
    height: 320px;
}

.small_img img{
    height: 100px;
    width: 200px;
    margin-right: 20px;
}


.divid{
    width: 170px!important;
}

.address_sec h2{
    font-size: 28px;
    font-weight: bold;
            font-family: 'Campton'!important;

}

span.bg-warning{
    border-radius: 4px!important;
}

.address{
                font-family: 'Campton Book'!important;


}

.address-text{
    font-weight: 600!important;
}

.nav-tabs{
                    font-family: 'Campton Book'!important;
                    font-weight: bold;


}
/*
.nav-tabs li {
        font-family: 'Campton Book';
    font-weight: 600!important;
}

.custom_tabs, .nav-tabs li{
        font-family: 'Campton Book';
    font-weight: 600!important;
}*/

.nav-tabs li a{
        font-family: 'Campton Book';
    font-weight: 600!important;

}

/*.custom_tabs, .nav-tabs li a.active{
    border-bottom: 2px solid #f47824!important;
}
*/

/*.custom_tabs, .nav-tabs li a.active:hover{
    border-color:red;
}*/

.heading{

            font-family: 'Campton Book';

}
.inner-right img{
    border: 1px solid #fff;
    width: 100px;
    border-radius: 7px;
}


.Selectnumber{
    font-size: 14px;
                font-family: 'Campton Book';

}

.about_tab h4{
text-transform: uppercase;
font-size: 12px;
}

.about_tab p{
font-family: 'Campton Book';
    font-weight: 600;

color: #2c2627cf;
}

.share-details{
   font-family: 'Campton Book';
    font-size: 16px;
    text-transform: capitalize;
}

.author-name{


   font-family: 'Campton Book';
    font-weight: 900;
}


span.bg-warning{
    padding: 6px 13px;
}


#gallery img{
    border-radius: 6px;
    border: 1px solid #fff;
}

.Professional_button a:hover{
    color: #F47824;
}



a:hover{
    text-decoration: none;
}

.btnlink{
	text-decoration:underline;
	color:blue;
}	