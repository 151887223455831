*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
}
body {
  font-family: campton !important;
}

@font-face {
  font-family: "exotic";
  src: local("exotic"),
    url(../assets/Exotc/Exotc350BdBTBold.ttf) format("truetype");
}

@font-face {
  font-family: "campton";
  src: local("campton"),
    url(../assets/campton/campton-book.ttf) format("truetype");
}
